<template>
  <div class="process">
    <div class="box-card" style="padding: 20px;" >
      
      <div style="margin-bottom: 10px;float: left;" class="box-title">角色管理</div>

      <div style="float: right;margin-top: -8px;margin-bottom: 20px;">
        <span style="font-size: 13px;font-weight: 400">角色名：</span>
        <el-input v-model="userInput" style="width: 200px" clearable placeholder="请输入关键字" size="mini"></el-input>
        <el-button size="mini" style="margin-left: 15px" type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
      </div>
      <spread-table v-loading="loading" :beforeRouteName="beforeRouteName" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </div>
    <MenuList :dialogTableVisible="showMenuForm" v-if="showMenuForm" :id="id" @close="close" />
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { roleData } from "./roleData";
import { clickButtons, currentChanges, sizeChanges, getUserLists, DateChanges, closes } from "./roleMethods";
import MenuList from "./components/MenuList.vue"
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    MenuList
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      getUserList()
    })
    let data = reactive({
      drafrList: [
        { name: '角色编号', text: 'id' },
        { name: '角色名称', text: 'roleName' },
        { name: '修改人', text: 'updateUser' },
        { name: '创建时间', text: 'createTime' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      beforeRouteName: 'roleManage',
      loading: true,
      spreadTable: [],
      tableHeight: 400,
      operation: ['编辑', '删除'],
      pageType: 1,
      lastState: 1000,
      filetype: null,
      showMenuForm: false,
      userInput: '',
      id: null
    })
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let DateChange = (val) => { DateChanges(val, data) }
    let close = (val) => { closes(val, data) }
    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      DateChange,
      close
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.process{
  height: calc(100vh - 160px);
  border-radius: 6px;
  background-color: #fff;
  padding:20px;
  overflow: auto;

}
</style>